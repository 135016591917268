export default {
  path: '/voucher',
  component: (): any => import('@/views/voucher/VoucherPage.vue'),
  meta: {
    auth: true,
    title: 'voucher',
    allowedRoles: ['SUPER_ADMIN', 'ADMIN']
  },
  children: [
    {
      path: 'list',
      name: 'Voucher',
      component: (): any => import('@/views/voucher/pages/VoucherList.vue'),
      meta: {
        auth: true,
        title: 'บัตรส่วนลด',
        icon: 'mdi-ticket-percent',
        isMenu: true,
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'info/:id',
      name: 'VoucherInfo',
      component: (): any => import('@/views/voucher/pages/VoucherInfo.vue'),
      meta: {
        auth: true,
        title: 'รายละเอียดบัตรส่วนลด',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    },
    {
      path: 'create',
      name: 'VoucherCreate',
      component: (): any => import('@/views/voucher/pages/VoucherCreate.vue'),
      meta: {
        auth: true,
        title: 'สร้างบัตรส่วนลด',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      }
    }
  ]
}
