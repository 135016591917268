export default {
  path: 'other',
  component: (): any => import('@/views/setting/pages/other/Other.vue'),
  meta: {
    title: 'อื่นๆ',
    auth: true,
    icon: 'mdi-cog',
    allowedRoles: ['SUPER_ADMIN', 'ADMIN']
  },
  children: [
    {
      path: 'member-tier',
      name: 'SettingMemberTier',
      component: (): any => import('@/views/setting/pages/other/pages/member/MemberTier.vue'),
      meta: {
        title: 'เงื่อนไขสมาชิก',
        auth: true,
        isSettingMenu: true,
        icon: 'mdi-cog',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      },
      redirect: {
        name: 'SettingMemberTierList'
      },
      children: [
        {
          path: '',
          name: 'SettingMemberTierList',
          component: (): any => import('@/views/setting/pages/other/pages/member/pages/SettingMemberTierList.vue'),
          meta: {
            auth: true,
            isSettingMenu: true,
            title: 'เงื่อนไขสมาชิก',
            allowedRoles: ['SUPER_ADMIN', 'ADMIN']
          }
        },
        {
          path: 'create',
          name: 'SettingMemberTierCreate',
          component: (): any => import('@/views/setting/pages/other/pages/member/pages/SettingMemberTierCreate.vue'),
          meta: {
            auth: true,
            title: 'เพิ่มเงื่อนไขสมาชิก',
            allowedRoles: ['SUPER_ADMIN', 'ADMIN']
          }
        },
        {
          path: 'edit/:id',
          name: 'SettingMemberTierEdit',
          component: (): any => import('@/views/setting/pages/other/pages/member/pages/SettingMemberTierEdit.vue'),
          meta: {
            auth: true,
            title: 'แก้ไขเงื่อนไขสมาชิก',
            allowedRoles: ['SUPER_ADMIN', 'ADMIN']
          }
        }
      ]
    },

    {
      path: 'tax',
      name: 'SettingTax',
      component: (): any => import('@/views/setting/pages/other/pages/tax/Tax.vue'),
      meta: {
        title: 'ข้อมูลใบกำกับภาษี',
        auth: true,
        isSettingMenu: true,
        icon: 'mdi-cog',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN']
      },
      redirect: {
        name: 'SettingTaxList'
      },
      children: [
        {
          path: '',
          name: 'SettingTaxList',
          component: (): any => import('@/views/setting/pages/other/pages/tax/pages/SettingTaxList.vue'),
          meta: {
            auth: true,
            isSettingMenu: true,
            title: 'ข้อมูลใบกำกับภาษี',
            allowedRoles: ['SUPER_ADMIN', 'ADMIN']
          }
        },
        {
          path: 'create',
          name: 'SettingTaxCreate',
          component: (): any => import('@/views/setting/pages/other/pages/tax/pages/SettingTaxCreate.vue'),
          meta: {
            auth: true,
            title: 'เพิ่มข้อมูลใบกำกับภาษี',
            allowedRoles: ['SUPER_ADMIN', 'ADMIN']
          }
        },
        {
          path: 'edit/:id',
          name: 'SettingTaxEdit',
          component: (): any => import('@/views/setting/pages/other/pages/tax/pages/SettingTaxEdit.vue'),
          meta: {
            auth: true,
            title: 'แก้ไขข้อมูลใบกำกับภาษี',
            allowedRoles: ['SUPER_ADMIN', 'ADMIN']
          }
        },
        {
          path: 'details/:id',
          name: 'SettingTaxDetails',
          component: (): any => import('@/views/setting/pages/other/pages/tax/pages/SettingTaxDetails.vue'),
          meta: {
            auth: true,
            title: 'ข้อมูลใบกำกับภาษี',
            allowedRoles: ['SUPER_ADMIN', 'ADMIN']
          }
        }
      ]
    },

    {
      path: 'printer-config',
      name: 'SettingPrinterConfig',
      component: (): any => import('@/views/setting/pages/other/pages/PrinterConfig.vue'),
      meta: {
        title: 'ปริ้นเตอร์',
        auth: true,
        isSettingMenu: true,
        icon: 'mdi-cog',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'SALE']
      }
    },
    {
      path: 'word-receipt',
      name: 'SettingWordReceipt',
      component: (): any => import('@/views/setting/pages/other/pages/WordReceipt.vue'),
      meta: {
        title: 'จัดการข้อความในใบเสร็จ',
        auth: true,
        isSettingMenu: true,
        icon: 'mdi-cog',
        allowedRoles: ['SUPER_ADMIN', 'ADMIN', 'SALE']
      }
    }
  ]
}
