import store from '@/store'

interface IToken {
  key: string
  value: string
}

export const getUserAccessToken = (): string => {
  const accessToken: string = store.getters['User/accessToken']
  const tokenExpire: number = store.getters['User/tokenExpire']
  const dateTime: number = Math.floor(new Date().getTime() / 1000)

  if (!accessToken || (!!tokenExpire && tokenExpire <= dateTime)) {
    store.dispatch('User/clearAll')
    return ''
  }
  return accessToken
}

export const getUserAuthToken = (): IToken | null => {
  const token: IToken = {
    key: 'Authorization',
    value: 'Bearer'
  }
  const accessToken: string = getUserAccessToken()
  if (accessToken) {
    token.value += ` ${accessToken}`
    return token
  }
  return null
}

export const getMerchantAccessToken = (): string => {
  const accessToken: string = store.getters['Merchant/accessToken']
  const tokenExpire: number = store.getters['Merchant/tokenExpire']
  const dateTime: number = Math.floor(new Date().getTime() / 1000)

  if (!accessToken || (!tokenExpire && tokenExpire <= dateTime)) {
    store.dispatch('Merchant/clearAll')
    return ''
  }
  return accessToken
}

export const getMerchantAuthToken = (): IToken | null => {
  const token: IToken = {
    key: 'Authorization',
    value: 'Bearer'
  }
  const accessToken: string = getMerchantAccessToken()
  if (accessToken) {
    token.value += ` ${accessToken}`
    return token
  }
  return null
}

export const getUserRoles = (): string | undefined => {
  const role = store.getters['Merchant/getUserRoles']
  return role
}

export default {
  getUserAccessToken,
  getUserAuthToken,
  getMerchantAccessToken,
  getMerchantAuthToken,
  getUserRoles
}
